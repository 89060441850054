<template>
    <el-col class="cont sms-campaign-box">
       <!-- 面包屑  -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>营销云</el-breadcrumb-item>
             <el-breadcrumb-item>运营</el-breadcrumb-item>
             <el-breadcrumb-item to="/short_message">短信</el-breadcrumb-item>
             <el-breadcrumb-item>活动分析</el-breadcrumb-item>
          </el-breadcrumb>
          <el-button class="bg-gradient" @click="getback()">返回</el-button>
       </el-row>
      <el-row class="content-box">
         <el-row class="dates">
           <el-date-picker
             v-model="value1"
             type="daterange"
             range-separator="至"
             start-placeholder="开始日期"
             end-placeholder="结束日期">
           </el-date-picker>
         </el-row>
         <el-row class="boxes">
           <el-row class="previews">
             <el-row class="vi-analystic-chart">预览</el-row>
             <el-row class="preview"><img src="../../../../assets/bgphone.6a2117f.png"/></el-row>
           </el-row>
           <el-row style="width: 40%">
             <el-row class="box">
               <el-row style="height: 40%">
                 <el-row class="Participate">
                   <el-row class="Participatebox">
                     <span class="participation"
                       >参与用户数
                       <el-popover
                         placement="top-start"
                         title="参与用户数"
                         width="200"
                         trigger="hover"
                         content="活动正式下发的去重用户数。在某些情况下，因为用户缺少相关的属性信息，针对这些缺失用户是不会下发活动的，
                         所以他们不在参与用户数的统计范围内。比如：Push 活动，缺少设备 token 的用户无法下发，不在参与用户统计之内。"
                       >
                         <el-button slot="reference" class="ioc"
                           ><i class="el-icon-question"></i
                         ></el-button> </el-popover
                     ></span>
                     <span class="el-icon-refresh iocn"></span>
                   </el-row>
                   <el-row class="numberofpeople"
                     ><span class="amount">{{queryData.joinUserCount}}</span><span>人</span></el-row
                   >
                 </el-row>
                 <el-row class="percentconversion">
                   <el-row class="Participatebox">
                     <span class="participation"
                       >转化率
                       <el-popover
                         placement="top-start"
                         title="转化率"
                         width="200"
                         trigger="hover"
                         content="转化率 = 转化用户数/参与用户数"
                       >
                         <el-button slot="reference" class="ioc"
                           ><i class="el-icon-question"></i
                         ></el-button> </el-popover
                     ></span>
                     <span class="el-icon-refresh iocn"></span>
                   </el-row>
                   <el-row class="percentconversions">
                     <el-progress type="circle" :percentage="count"></el-progress>
                   </el-row>
                 </el-row>
               </el-row>
               <el-row class="databox">
                 <el-row class="turnovernumber">
                   <el-row class="turnovernumbers">活动转化数（累计数）</el-row>
                   <el-row class="iocns"><i class="el-icon-refresh"></i></el-row>
                 </el-row>
                 <el-row class="usernumber">
                   <el-row>
                     <el-badge :value="queryData.joinUserCount+'人'" class="item">
                       <el-button >参与用户数</el-button>
                     </el-badge>
                   </el-row>
                   <el-row>
                     <el-badge :value="queryData.reachUserCount+'人'" class="item">
                       <el-button >触达用户数</el-button>
                     </el-badge>
                   </el-row>
                   <el-row>
                     <el-badge :value="count+'人'" class="item">
                       <el-button >转化用户数</el-button>
                     </el-badge>
                   </el-row>
                 </el-row>
               </el-row>
             </el-row>
           </el-row>
         </el-row>
         <el-row class="timedata">
           <el-row class="titles">
             <el-radio-group
               v-model="tabPosition"
               style="margin-bottom: 30px">
               <el-radio-button :label="true">线图</el-radio-button>
               <el-radio-button :label="false">表格</el-radio-button>
             </el-radio-group>
           </el-row>
           <!-- 图表 -->
           <el-row
             v-show="this.tabPosition == true"
             id="echa"
             style="width: 100%; height: 300px"
           ></el-row>
           <!-- 表格 -->
           <el-table :data="information" style="width: 100%" v-show="this.tabPosition == false">
             <el-table-column prop="date"></el-table-column>
             <el-table-column prop="convertCount" label="触达用户数"></el-table-column>
             <el-table-column prop="reachCount" label="转化用户数"> </el-table-column>
           </el-table>
         </el-row>
      </el-row>
    </el-col>
</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
export default {
  watch: {
    hotelInfo: {
        handler(newVal, oldVal) {
          if (newVal.id !== oldVal.id && oldVal) {
            this.hotelId = this.hotelInfo.id
            this.graph()
          }
        },
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  data() {
    return {
      tabPosition: true,
      person: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      count:0,
      option: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["触达用户数", "转化用户数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "触达用户数",
            type: "line",
            data: [],
          },
          {
            name: "转化用户数",
            type: "line",
            data: [],
          },
        ],
      },
      action:'',
      information:[],
      percents:'',

       queryData:{
          joinUserCount:'',
          reachUserCount:'',
          id:'',
       }
    };
  },
  mounted() {
     const activityObj = JSON.parse(sessionStorage.getItem('publicaccount') || '{}')
     for (const k in this.queryData) k in activityObj && (this.queryData[k] = activityObj[k])
     this.count = Number(Math.ceil(activityObj.reachUserCount/activityObj.joinUserCount)) || 0
  },
  methods: {
    graph(){
      var myChart = this.$echarts.init(document.getElementById("echa"));
      this.action = this.queryData.id
      const url = urlObj.activityAnalysis+ `/${this.action}`;
      this.$axios.get(url).then((res) => {
        this.information = res.records
        this.information.map(item=>{
          this.option.xAxis.data.push(item.date)
          this.option.series[0].data.push(item.convertCount)
          this.option.series[1].data.push(item.reachCount)
        })
        myChart.setOption(this.option);
      })
    },
    //返回
    getback(){
        this.$router.go(-1)
    },
  },
};
</script>

<style scoped>
/*.main {*/
/*  width: 98%;*/
/*  height: 100%;*/
/*  background: #f7f7f8;*/
/*  margin: 10px;*/
/*  overflow: hidden;*/
/*}*/
.title {
  width: 100%;
  margin-top: 1rem;
}
.title span {
  display: inline-block;
  float: left;
  margin-left: 1rem;
  line-height: 40px;
  font-size: 18px;
}
.title .el-button {
  float: right;
  margin-right: 1rem;
}
.dates {
   margin-bottom: 20px;
}

.boxes {
  width: 100%;
  height: 700px;
  margin-left: 1rem;
  display: flex;
}
.preview {
  width: 99.6%;
  height: 100%;
  background-size: cover;
  margin: auto;
  margin-top: 1rem;
  text-align: center;
}
.previews {
  height: 100%;
  background: white;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.05);
}
.box {
  width: 100%;
  height: 635px;
  /* display: flex; */
  margin-left: 1rem;
}
.Participate {
  width: 49%;
  height: 78%;
  border: 1px solid black;
  margin-top: 3rem;
  float: left;
}
.percentconversion {
  width: 49%;
  height: 78%;
  border: 1px solid black;
  margin-top: 3rem;
  float: right;
  margin-left: 2%;
}
.Participatebox {
  margin-top: 1rem;
}
.participation {
  float: left;
  margin-left: 1rem;
  font-size: 14px;
}
.iocn {
  float: right;
  margin-right: 1rem;
}
.ioc {
  padding: 0px;
}
.amount {
  font-size: 3rem;
}

.numberofpeople {
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}
.percentconversions {
  text-align: center;
}
.vi-analystic-chart {
  font-size: 14px;
  padding-top: 1rem;
  padding-left: 1rem;
}

.databox {
  height: 60%;
  margin-top: 1rem;
}
.turnovernumber {
  width: 100%;
}
.turnovernumber .turnovernumbers {
  float: left;
  width: 50%;
  font-size: 14px;
}
.turnovernumber .iocns {
  width: 50%;
  float: right;
  text-align: right;
}

.usernumber .el-row {
  margin: 2rem 0px;
}

/deep/ .el-badge__content.is-fixed {
  position: absolute;
  top: 1rem;
  right: 0px;
  background: #9ddc87;
}
.timedata {
  height: 400px;
  margin: auto;
  margin-top: 6rem;
  width: 95%;
  box-shadow: 0 10px 15px 0 rgba(42, 8, 163, 0.08);
}
.Timetodate {
  font-size: 14px;
  padding-left: 1rem;
  float: left;
}
.el-radio-group {
  float: right;
}
.el-table{border: 1px solid #ebeef5;}
/deep/ .has-gutter tr th{text-align: center;}
/deep/ tbody .el-table__row td{text-align: center;}
@media screen and (max-width: 1366px) {
  .main {
    width: 1300px;
  }
}
</style>
